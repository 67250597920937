<template>
    <div class="copyright" :class="[customClass, $mq]">
        <a rel="noreferrer" target="_blank" :href="intowinUrl">
            <template v-if="['portrait'].includes($mq)">{{ $t('assets.copyright_intowin_1_short') }}</template>
            <template v-else>
                {{ $t('assets.copyright_intowin_1') }}
            </template>
            <span class="icon-heart"></span>
            <template v-if="['portrait'].includes($mq)">{{ $t('assets.copyright_intowin_2_short') }}</template>
            <template v-else>
                {{ $t('assets.copyright_intowin_2') }}
            </template>
        </a>
    </div>
</template>

<script>
import i18n from '@/i18n'

export default {
    props: {
        customClass: { type: [String, Array, Object, Function], default: '' }
    },
    data() {
        return {
            intowinUrl: 'https://intowin.io/'
        }
    },
    computed: {},
    methods: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.copyright {
    @include font-size(s);
    font-family: $text-light;
    color: $main-t30 !important;
    display: block;
    width: calc(100% - #{$menu-tools-min}* 2);
    // margin-left: calc(#{$menu-tools-min});
    height: rem(40px);
    line-height: rem(40px);
    text-align: center !important;
    position: fixed;
    bottom: 6px;
    left: #{$menu-tools-min} !important;

    a {
        @include interaction();
        @include inheritFont();
    }
    .icon-heart {
        @include background($image: img('heart_main_t30.svg'));
        display: inline-block;
        width: 9px;
        height: 9px;
    }

    // CUSTOM CLASSES
    &.login-copyright {
        color: $neutro-s50 !important;
        // position: static;
        position: inherit;
        display: block;
        width: auto;
        // height: 40px;
        line-height: 40px;

        .icon-heart {
            background-image: img('heart_neutro_s50.svg');
        }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss">
.copyright.portrait {
    display: block;

    &.login-copyright {
        @include font-size(xs);
        line-height: 18px;
        max-width: 50%;
        height: auto;
        min-width: 200px;
        text-align: left !important;
        margin-bottom: 6px;
    }
}
</style>
